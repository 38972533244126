import { Button, HtmlRenderer, spacing, styled, Success, useFormState } from '@mortgagehippo/ds';
import { fromBlueprintTask, TaskType } from '@mortgagehippo/tasks';
import { UnreachableCaseError, withErrorBoundary } from '@mortgagehippo/util';
import { get, trim } from 'lodash-es';
import { useContext, useMemo } from 'react';

import { AssetsTaskPreview } from './assets-task/assets-task-preview';
import { ChooseAgentTaskPreview } from './choose-agent-task/choose-agent-task-preview';
import { CreditPullTaskPreview } from './credit-pull-task/credit-pull-task-preview';
import { DataGatheringTaskPreview } from './data-gathering-task/data-gathering-task-preview';
import { DocumentSubmissionTaskPreview } from './document-submission-task/document-submission-task-preview';
import { DocusignTaskPreview } from './docusign-task/docusign-task-preview';
import { DocutechDisclosuresTaskPreview } from './docutech-disclosures-task/docutech-disclosures-task-preview';
import { EditorContext } from './editor-context';
import { EmployersTaskPreview } from './employers-task/employers-task-preview';
import { EncompassDisclosuresTaskPreview } from './encompass-disclosures-task/encompass-disclosures-task-preview';
import { HellosignTaskPreview } from './hellosign-task/hellosign-task-preview';
import { InformationTaskPreview } from './information-task/information-task-preview';
import { InviteApplicantsTaskPreview } from './invite-applicants-task/invite-applicants-task-preview';
import { PaymentTaskPreview } from './payment-task/payment-task-preview';
import { PricingTaskPreview } from './pricing-task/pricing-task-preview';
import { SendDocumentTaskPreview } from './send-document-task/send-document-task-preview';
import { SignDisclosuresTaskPreview } from './sign-disclosures-task/sign-disclosures-task-preview';
import { SubmitApplicationTaskPreview } from './submit-application-task/submit-application-task-preview';
import { VOIECascadeTaskPreview } from './voie-cascade-task/voie-cascade-task-preview';

const Container = styled.div`
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(4)};

  .public-DraftStyleDefault-ltr {
    text-align: center;
  }
`;

const CompleteButtonContainer = styled.div`
  padding: ${spacing(5)} 0 0;
  text-align: center;
`;

const Blank = () => null;

export const TaskPreview = withErrorBoundary(Blank)(() => {
  const { path } = useContext(EditorContext);
  const { values } = useFormState({ subscription: { values: true } });
  const blueprintTask = get(values, path, {});
  const task = useMemo(() => fromBlueprintTask(blueprintTask, 1), [blueprintTask]);
  const { customCompletion, completionContinueButtonLabel, completionDescription } = task?.meta || {};


  

  const handleComplete = async () => undefined;

  const renderComplete = () => (
    customCompletion ? (
      <Container>
        <HtmlRenderer value={trim(completionDescription)} />
        <CompleteButtonContainer>
          <Button importance="primary" icon="right-arrow" iconLocation="right">
            {completionContinueButtonLabel || 'Continue'}
          </Button>
        </CompleteButtonContainer>
      </Container>
     ) :
      <Success />
  );

  switch (task.typeName) {
    case TaskType.AssetsTask: {
      return (
        <AssetsTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.ChooseAgentTask: {
      return (
        <ChooseAgentTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.CreditPullTask: {
      return (
        <CreditPullTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.DataGatheringTask: {
      return (
        <DataGatheringTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.DocumentSubmissionTask: {
      return (
        <DocumentSubmissionTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.DocutechDisclosuresTask: {
      return (
        <DocutechDisclosuresTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.DocusignTask: {
      return (
        <DocusignTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.HellosignTask: {
      return (
        <HellosignTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.InformationTask: {
      return (
        <InformationTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.InviteApplicantsTask: {
      return (
        <InviteApplicantsTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.EmployersTask: {
      return (
        <EmployersTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.EncompassDisclosuresTask: {
      return (
        <EncompassDisclosuresTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.PaymentTask: {
      return (
        <PaymentTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.PreQualificationTask:
    case TaskType.SnapdocsClosingTask: {
      return null;
    }
    case TaskType.PricingTask: {
      return (
        <PricingTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.SendDocumentTask: {
      return (
        <SendDocumentTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.SignDisclosuresTask: {
      return (
        <SignDisclosuresTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.SubmitApplicationTask: {
      return (
        <SubmitApplicationTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    case TaskType.VOIECascadeTask: {
      return (
        <VOIECascadeTaskPreview
          task={task}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      );
    }
    default: {
      throw new UnreachableCaseError(task);
    }
  }
});
