import {
  Choice,
  FormSection,
  Input,
  type ISelectOption,
  Select,
  useFormValue,
  YesNo,
} from '@mortgagehippo/ds';
import { type ITaskGroup, TaskType } from '@mortgagehippo/tasks';
import { get } from 'lodash-es';
import { useEffect } from 'react';

export interface ITaskEditorDetails {
  name: string;
}

const borrowerPortalTaskTypes = [
  {
    label: 'Assets Task',
    value: TaskType.AssetsTask,
  },
  {
    label: 'Choose Agent',
    value: TaskType.ChooseAgentTask,
  },
  {
    label: 'Co-Borrowers',
    value: TaskType.InviteApplicantsTask,
  },
  {
    label: 'Credit Pull',
    value: TaskType.CreditPullTask,
  },
  {
    label: 'Data Gathering',
    value: TaskType.DataGatheringTask,
  },
  {
    label: 'Document Submission',
    value: TaskType.DocumentSubmissionTask,
  },
  {
    label: 'Employers Task',
    value: TaskType.EmployersTask,
  },
  {
    label: 'Hellosign Task',
    value: TaskType.HellosignTask,
  },
  {
    label: 'Information',
    value: TaskType.InformationTask,
  },
  {
    label: 'Pricing Task',
    value: TaskType.PricingTask,
  },
  {
    label: 'VOIE Cascade Task',
    value: TaskType.VOIECascadeTask,
  },
];

const lenderPortalTaskTypes = [
  {
    label: 'Assets Task',
    value: TaskType.AssetsTask,
  },
  {
    label: 'Data Gathering',
    value: TaskType.DataGatheringTask,
  },
  {
    label: 'Employers Task',
    value: TaskType.EmployersTask,
  },
  {
    label: 'Submit Application Task',
    value: TaskType.SubmitApplicationTask,
  },
  {
    label: 'VOIE Cascade Task',
    value: TaskType.VOIECascadeTask,
  },
];

const DUE_DATE_OPTIONS: ISelectOption[] = [
  {
    label: 'Same day',
    value: 0,
  },
  {
    label: '1 day',
    value: 1,
  },
  {
    label: '2 days',
    value: 2,
  },
  {
    label: '3 days',
    value: 3,
  },
  {
    label: '4 days',
    value: 4,
  },
  {
    label: '5 days',
    value: 5,
  },
  {
    label: '6 days',
    value: 6,
  },
  {
    label: 'No due date',
    value: null,
  },
];

export const TaskEditorDetails = (props: ITaskEditorDetails) => {
  const { name } = props;
  const [blueprint] = useFormValue('json');
  const [type] = useFormValue<string>('type');
  const [isFollowUp] = useFormValue<boolean | undefined>(`${name}.meta.follow_up`);
  const [, setDaysForDueDate] = useFormValue<number | undefined>(`${name}.meta.days_for_due_date`);

  const taskGroups = get(blueprint, 'task_groups.items', []) as ITaskGroup[];
  const options =
    type === 'LenderPortalBlueprint' ? lenderPortalTaskTypes : borrowerPortalTaskTypes;

  const groupOptions: ISelectOption[] = (taskGroups || []).map((group) => ({
    label: group.label,
    value: group.key,
  }));

  useEffect(() => {
    if (!isFollowUp) {
      setDaysForDueDate(undefined);
    }
  }, [isFollowUp, setDaysForDueDate]);

  return (
    <>
      <Select.Box name={`${name}.type_name`} label="Task Type" options={options} />
      <Input.Box name={`${name}.key`} label="key" required />
      <Input.Box name={`${name}.meta.title`} label="Task Title" required />
      <Select.Box name={`${name}.meta.group_key`} label="Group" options={groupOptions} />

      <Choice.Box
        name={`${name}.meta.follow_up`}
        label="What task type is this?"
        columns={2}
        options={[
          { value: false, label: 'Application task' },
          { value: true, label: 'Follow-up task' },
        ]}
      />

      {!!isFollowUp && (
        <Select.Box
          name={`${name}.meta.days_for_due_date`}
          label="Default due date (days after opening)"
          options={DUE_DATE_OPTIONS}
          initialValue={3}
        />
      )}

      <Choice.Box
        name={`${name}.state`}
        label="Initial State"
        columns={2}
        options={[
          { value: 'open', label: 'Open' },
          { value: 'locked', label: 'Locked' },
        ]}
        required
      />

      <YesNo.Box
        name={`${name}.meta.common`}
        label="Common Task"
        labelYes="Common"
        labelNo="Individual"
        required
      />

      <YesNo.Box
        name={`${name}.meta.visible`}
        label="Initial Visibility"
        labelYes="Visible"
        labelNo="Hidden"
        initialValue
        required
      />

      <YesNo.Box
        name={`${name}.meta.requires_authentication`}
        label="Requires Authentication"
        initialValue={false}
        required
      />

      <YesNo.Box
        name={`${name}.meta.requires_email_verification`}
        label="Requires Email Verification"
        initialValue={false}
        required
      />

      <YesNo.Box
        name={`${name}.meta.frozen_on_application_freeze`}
        label="Freeze When Application is Frozen"
        initialValue={false}
      />

      <FormSection title="Buttons">
        <Input.Box
          name={`${name}.meta.next_button_label`}
          label="Continue / Complete button label"
          description="This will set the button label for every panel. If you have customized the buttons at a panel's level the value from the panel will be used."
          placeholder="Submit"
        />
        <Input.Box
          name={`${name}.meta.next_button_label_when_completed`}
          label="Continue / Complete button label if the task has been completed"
          description="This will set the button label for every panel if the task has been completed. If you have customized the buttons at a panel's level the value from the panel will be used."
          placeholder="Update"
        />
      </FormSection>
    </>
  );
};
